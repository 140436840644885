export default {
  EVENT                   : 'data-event',
  INIT                    : 'data-event:init',
  LOCALE                  : 'data-event:locale',
  LOGIN                   : 'data-event:login',
  LOGOUT                  : 'data-event:logout',
  SIGN_UP                 : 'data-event:sign_up',
  ADD_TO_CART             : 'data-event:add_to_cart',
  UPDATE_CART_ITEM        : 'data-event:update_cart_item',
  REMOVE_FROM_CART        : 'data-event:remove_from_cart',
  VIEW_ITEM               : 'data-event:view_item',
  BEGIN_CHECKOUT          : 'data-event:begin_checkout',
  ADD_SHIPPING_INFO       : 'data-event:add_shipping_info',
  ADD_PAYMENT_INFO        : 'data-event:add_payment_info',
  PURCHASE                : 'data-event:purchase',
  SEARCH                  : 'data-event:search',
  ACCOUNT_DELETE          : 'data-event:account_delete',
  ACCOUNT_DISABLE         : 'data-event:account_disable',
  ACCOUNT_ENABLE          : 'data-event:account_enable',
  ACCOUNT_UPDATE          : 'data-event:account_update',
  PUSH_SUBSCRIPTION_CHANGE: 'data-event:push_subscription_change'
}
